.hero {
	margin: 0 -16px 16px;
	aspect-ratio: 32/9;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background-position: center;
	background-size: cover;
	background-color: gray;
	display: block;
	position: relative;
	line-height: 0;
}
.hero.hero img {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.hero iframe {
	width: 100%;
	height: 100%;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	backdrop-filter: blur(16px);
	border: 0;
}
.caption {
	min-height: 16px;
	position: absolute;
	display: inline-block;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	max-width: 90%;
	text-decoration: underline;
	line-height: 1.25;
}
