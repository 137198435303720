.Column_root__nyozZ {
	border-radius: 8px;
	background: var(--is-light-mode, #FFFFFF) var(--is-dark-mode, #111111);
	background: light-dark(#FFFFFF, #111111);
	color: var(--is-light-mode, black) var(--is-dark-mode, #eeeeee);
	color: light-dark(black, #eeeeee);
	min-height: 64px;
	position: relative;
}
.Column_padded__1EABq {
	padding: 0 16px 16px;
	display: flow-root;
}
.Column_margin__ZEMzZ {
	margin-bottom: 16px;
}
.Column_sticky__v8KCC {
	top: 16px;
	position: -webkit-sticky;
	position: sticky;
	max-height: calc(100vh - 32px);
}
.Column_flex__gOYkR {
	display: flex;
}

.Column_floatingLayers__MNP5S::before, .Column_floatingLayers__MNP5S::after {
	position: absolute;
	content: "";
	display: block;
	width: 24px;
	height: 48px;
	background: transparent;
	box-shadow: 0 -24px 0 0 var(--is-light-mode, #FFFFFF) var(--is-dark-mode, #111111);
	box-shadow: 0 -24px 0 0 light-dark(#FFFFFF, #111111);
}

.Column_attachedBottom__SDvVm {
	margin-bottom: -16px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	padding-bottom: 16px;
}
.Column_attachedBottom__SDvVm::before, .Column_attachedBottom__SDvVm::after {
	bottom: 0;
}
.Column_attachedBottom__SDvVm::before {
	right: 100%;
	border-bottom-right-radius: 24px;
}
.Column_attachedBottom__SDvVm::after {
	left: 100%;
	border-bottom-left-radius: 24px;
}

.Column_attachedTop__0_IY_ {
	margin-top: -16px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	padding-top: 16px;
}
.Column_attachedTop__0_IY_::before, .Column_attachedTop__0_IY_::after {
	top: 0;
}
.Column_attachedTop__0_IY_::before {
	right: 100%;
	border-top-right-radius: 24px;
}
.Column_attachedTop__0_IY_::after {
	left: 100%;
	border-top-left-radius: 24px;
}

.Column_attachedLeft__cJVTl {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.Column_attachedLeft__cJVTl::before, .Column_attachedLeft__cJVTl::after {
	right: calc(100% - 8px);
}
.Column_attachedLeft__cJVTl::before {
	border-top-left-radius: 24px;
	top: 100%;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
	        clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
}
.Column_attachedLeft__cJVTl::after {
	border-bottom-left-radius: 24px;
	bottom: 100%;
	-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
	        clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
	box-shadow: 0 24px 0 0 var(--is-light-mode, #FFFFFF) var(--is-dark-mode, #111111);
	box-shadow: 0 24px 0 0 light-dark(#FFFFFF, #111111);
}
.Column_attachedLeft__cJVTl > .Column_styleHelper___qkzS {
	background-color: var(--is-light-mode, #FFFFFF) var(--is-dark-mode, #111111);
	background-color: light-dark(#FFFFFF, #111111);
	position: absolute;
	right: 100%;
	top: 0;
	bottom: 0;
	width: 16px;
}

.Column_attachedRight__0TWb4 {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.Column_attachedRight__0TWb4::before, .Column_attachedRight__0TWb4::after {
	left: calc(100% - 8px);
}
.Column_attachedRight__0TWb4::before {
	border-top-right-radius: 24px;
	top: 100%;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
	        clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
}
.Column_attachedRight__0TWb4::after {
	border-bottom-right-radius: 24px;
	bottom: 100%;
	-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
	        clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
	box-shadow: 0 24px 0 0 var(--is-light-mode, #FFFFFF) var(--is-dark-mode, #111111);
	box-shadow: 0 24px 0 0 light-dark(#FFFFFF, #111111);
}
.Column_attachedRight__0TWb4 > .Column_styleHelper___qkzS {
	background-color: var(--is-light-mode, #FFFFFF) var(--is-dark-mode, #111111);
	background-color: light-dark(#FFFFFF, #111111);
	position: absolute;
	left: 100%;
	top: 0;
	bottom: 0;
	width: 16px;
}
.Column_flattenBottom__CePuM {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.Column_flattenBottom__CePuM.Column_attachedRight__0TWb4::before,
.Column_flattenBottom__CePuM.Column_attachedLeft__cJVTl::before {
	display: none;
}
.Column_flattenTop__6Xlms {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.Column_flattenTop__6Xlms.Column_attachedRight__0TWb4::after,
.Column_flattenTop__6Xlms.Column_attachedLeft__cJVTl::after {
	display: none;
}
.Column_flattenTop__6Xlms.Column_sticky__v8KCC {
	top: 0;
}
@media screen and (max-width: 1023px) {
	.Column_attachedRight__0TWb4::before,
	.Column_attachedRight__0TWb4::after,
	.Column_attachedRight__0TWb4 > .Column_styleHelper___qkzS {
		display: none;
	}
	.Column_attachedRight__0TWb4 {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}
@media screen and (max-width: 480px) {
	.Column_attachedTop__0_IY_::after {
		width: 16px;
		clip: rect(0,8px,24px,0)
	}
}

.Comments_root__kTxgE {
	min-height: 369px;
}
.Comments_root__kTxgE giscus-widget {
	display: flex;
	color-scheme: inherit;
}
.Comments_root__kTxgE giscus-widget::part(iframe) {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	color-scheme: inherit;
}

/* font-size: 41.079*0.85457^x */
.Heading_root__FvaaN {
	margin: 0.25em 0 0.125em;
}
h1.Heading_root__FvaaN {
	margin-top: 8px;
	font-size: 35.1px;
}
h2.Heading_root__FvaaN {
	font-size: 30px;
}
h3.Heading_root__FvaaN {
	font-size: 25.6px;
}
h4.Heading_root__FvaaN {
	font-size: 21.9px;
}
h5.Heading_root__FvaaN {
	font-size: 18.7px;
}
h6.Heading_root__FvaaN {
	font-size: 16px;
}
.Heading_root__FvaaN + p,
.Heading_root__FvaaN + ul {
	margin-top: 0;
}
.Heading_withLink__7Kp_f {
	position: relative;
}
.Heading_withLink__7Kp_f > a:first-child {
	position: absolute;
	opacity: 0;
	background: var(--is-light-mode, black) var(--is-dark-mode, white);
	background: light-dark(black, white);
	width: 16px;
	height: 16px;
	top: calc(50%);
	right: 100%;
	transform: translateY(-50%);
}

.Heading_withLink__7Kp_f:hover > a:first-child,
.Heading_withLink__7Kp_f > a:first-child:focus {
	opacity: 1;
	transition: opacity 0.01s 0.1s;
	-webkit-mask-image: url(/_next/static/media/link.98ae1fc8.svg);
	        mask-image: url(/_next/static/media/link.98ae1fc8.svg);
}
.Heading_padded__LtP7S {
	margin-left: 16px;
	margin-right: 16px;
}

.error_root__Jt4xy {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 0 20px;
	text-align: center;
}

.ArticleMarkdown_hero__0345F {
	margin: 0 -16px 16px;
	aspect-ratio: 32/9;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background-position: center;
	background-size: cover;
	background-color: gray;
	display: block;
	position: relative;
	line-height: 0;
}
.ArticleMarkdown_hero__0345F.ArticleMarkdown_hero__0345F img {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.ArticleMarkdown_hero__0345F iframe {
	width: 100%;
	height: 100%;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	-webkit-backdrop-filter: blur(16px);
	        backdrop-filter: blur(16px);
	border: 0;
}
.ArticleMarkdown_caption__FKOm1 {
	min-height: 16px;
	position: absolute;
	display: inline-block;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	max-width: 90%;
	text-decoration: underline;
	line-height: 1.25;
}

.AboutMe_center__GnCtl {
	margin: 24px 0 8px;
}
.AboutMe_aboutMeAnimationLink__fkQB4 {
	text-decoration: underline;
	cursor: pointer;
}

